import React from 'react';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const PeriodPickerButton = ({ onClick, disabled, children, sx }) => (
  <Button onClick={onClick} disabled={disabled} sx={sx}>
    <Typography variant="buttonXXL" color="textLight.primary">
      {children}
    </Typography>
  </Button>
);

PeriodPickerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

PeriodPickerButton.defaultProps = {
  disabled: false,
  sx: {},
};

export default PeriodPickerButton;

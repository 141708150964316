// redux/slices/period.js
import { createSlice } from '@reduxjs/toolkit';
import { getInitialPeriodState } from 'src/utils/periodUtils';

const initialState = {
  ...getInitialPeriodState(), // Initialize state using a helper function
  error: null,
  periodMessage: '',
  havePeriod: false,
  periodType: 'month',
  displayClientsMonthFinished: false,
};

const slice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    setPeriod(state, action) {
      state.period = action.payload;
    },
    setPeriodYear(state, action) {
      state.periodYear = action.payload;
    },
    setPeriodMonth(state, action) {
      state.periodMonth = action.payload.toString().padStart(2, '0');
    },
    setHavePeriod(state, action) {
      state.havePeriod = action.payload;
    },
    setPeriodType(state, action) {
      state.periodType = action.payload;
    },
    setPeriodMessage(state, action) {
      state.periodMessage = action.payload;
    },
    setDisplayClientsMonthFinished(state, action) {
      state.displayClientsMonthFinished = action.payload;
    },
  },
});

export const {
  setPeriod,
  setPeriodYear,
  setPeriodMonth,
  setHavePeriod,
  setPeriodType,
  setPeriodMessage,
  setDisplayClientsMonthFinished,
} = slice.actions;
// Reducer
export default slice.reducer;

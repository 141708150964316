// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import useUserConsultantId from 'src/hooks/useUserConsultantId';
import { useAuthContext } from 'src/auth/useAuthContext';
import SvgColor from 'src/components/svg-color';
import { IconDevToLogoLight } from 'src/components/icon-components-ph';

// ----------------------------------------------------------------------
const icon = (name) => <SvgColor src={`/assets/icons/custom/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  newspaper: icon('newspaper'),
  user: icon('user-male'),
  users: icon('users'),
  teams: icon('contacts'),
  profile: icon('user-male'),
  consultants: icon('star'),
  absence: icon('switch'),
  clients: icon('heart'),
  resources: icon('book-closed'),
  time: icon('clock'),
  invoice: icon('document-stack'),
  finances: icon('coins'),
  reports: icon('calendar-date'),
  dashboard: icon('gauge'),
  partners: icon('briefcase'),
  administrate: icon('settings'),
  home: icon('home-house'),
  dev: icon('dev'),
};

const useDynamicNavConfig = () => {
  const userId = useUserConsultantId();
  const { user } = useAuthContext();

  // Team specific items
  let teamObjects = [];

  user.consultant?.teams?.forEach((team) => {
    if (team?.type === 'client' && team?.team_lead_id === user.consultant?.id) {
      teamObjects.push({
        title: team?.name,
        path: PATH_DASHBOARD.team.profile(team?.id),
      });
    }
  });

  return [
    // {
    //   subheader: 'Root Digital',
    //   items: [
    //     {
    //       title: 'Dashboard',
    //       path: PATH_DASHBOARD.app,
    //       icon: ICONS.dashboard,
    //     },
    //   ],
    // },
    {
      subheader: 'Beet',
      items: [
        {
          title: 'Home',
          path: PATH_DASHBOARD.app,
          icon: ICONS.home,
        },
        {
          title: 'News',
          path: PATH_DASHBOARD.organization.news,
          icon: ICONS.newspaper,
          roles: [
            'SUPER_ADMIN',
            'BOARD_OF_DIRECTORS',
            'TEAM_LEAD',
            'CHANNEL_TEAM_LEAD',
            'ADMIN',
            'ECONOMY',
            'HUMAN_RESOURCES',
            'CLIENT_MANAGER',
            'CONSULTANT',
            'LIA',
            // 'SUB_CONSULTANT',
          ],
        },
        {
          title: 'Consultants',
          path: PATH_DASHBOARD.organization.consultant,
          icon: ICONS.consultants,
        },
        {
          title: 'Clients',
          path: PATH_DASHBOARD.organization.client,
          icon: ICONS.clients,
          roles: [
            'SUPER_ADMIN',
            'BOARD_OF_DIRECTORS',
            'TEAM_LEAD',
            'CHANNEL_TEAM_LEAD',
            'ADMIN',
            'ECONOMY',
            'HUMAN_RESOURCES',
            'CLIENT_MANAGER',
            'CONSULTANT',
            // 'LIA',
            // 'SUB_CONSULTANT',
          ],
        },
        { title: 'Teams', path: PATH_DASHBOARD.organization.team, icon: ICONS.teams },
        {
          title: 'Monthly Reports',
          path: PATH_DASHBOARD.organization.monthlyReports,
          icon: ICONS.reports,
          roles: [
            // 'SUB_CONSULTANT',
            // 'LIA',
            // 'CONSULTANT',
            'SUPER_ADMIN',
            // 'ADMIN',
            // 'BOARD_OF_DIRECTORS',
            'ECONOMY',
            // 'HUMAN_RESOURCES',
            'TEAM_LEAD',
            // 'CHANNEL_TEAM_LEAD',
            // 'CLIENT_MANAGER',
          ],
        },
        {
          title: 'Finances',
          path: PATH_DASHBOARD.finances.root,
          icon: ICONS.finances,
          roles: [
            'SUPER_ADMIN',
            'BOARD_OF_DIRECTORS',
            'TEAM_LEAD',
            // 'CHANNEL_TEAM_LEAD',
            // 'ADMIN',
            'ECONOMY',
            // 'HUMAN_RESOURCES',
            // 'CLIENT_MANAGER',
            // 'CONSULTANT',
            // 'LIA',
            // 'SUB_CONSULTANT',
          ],
          children: [
            {
              title: 'Invoices',
              path: PATH_DASHBOARD.invoice.list,
              roles: [
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                // 'ADMIN',
                'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
            { title: 'Media Account', path: PATH_DASHBOARD.budget.list },
            {
              title: 'Rootlytics',
              path: PATH_DASHBOARD.rootlytics.root,
              roles: [
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                // 'ADMIN',
                'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
          ],
        },
        {
          title: 'Administrate',
          path: PATH_DASHBOARD.administrate.root,
          icon: ICONS.administrate,
          roles: [
            'SUPER_ADMIN',
            // 'BOARD_OF_DIRECTORS',
            // 'TEAM_LEAD',
            // 'CHANNEL_TEAM_LEAD',
            // 'ADMIN',
            // 'ECONOMY',
            // 'HUMAN_RESOURCES',
            // 'CLIENT_MANAGER',
            // 'CONSULTANT',
            // 'LIA',
            // 'SUB_CONSULTANT',
          ],
          children: [
            {
              title: 'Users',
              path: PATH_DASHBOARD.administrate.user.root,
              roles: [
                'SUPER_ADMIN',
                // 'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                'ADMIN',
                // 'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
            {
              title: 'Channels',
              path: PATH_DASHBOARD.administrate.channel.root,
              roles: [
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                // 'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                // 'ADMIN',
                // 'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
            {
              title: 'Partners',
              path: PATH_DASHBOARD.administrate.partner.root,
              roles: [
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                // 'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                'ADMIN',
                // 'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
            {
              title: 'licenses',
              path: PATH_DASHBOARD.administrate.license.root,
              roles: [
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                // 'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                'ADMIN',
                // 'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ],
            },
          ],
        },
      ],
    },
    // PERSONAL
    {
      subheader: (user.name && user.name != ' ' && user.name) || 'Me',
      items: [
        {
          title: 'Profile',
          path: PATH_DASHBOARD.consultant.profile(userId),
          icon: ICONS.profile,
          roles: [
            'SUB_CONSULTANT',
            'LIA',
            'CONSULTANT',
            'TEAM_LEAD',
            'CHANNEL_TEAM_LEAD',
            'CLIENT_MANAGER',
            'BOARD_OF_DIRECTORS',
            'HUMAN_RESOURCES',
            'ECONOMY',
            'SUPER_ADMIN',
          ],
        },
        {
          title: 'Absence',
          path: PATH_DASHBOARD.personal.report,
          icon: ICONS.reports,
          roles: [
            'SUPER_ADMIN',
            'BOARD_OF_DIRECTORS',
            'TEAM_LEAD',
            'CHANNEL_TEAM_LEAD',
            'ADMIN',
            'ECONOMY',
            'HUMAN_RESOURCES',
            'CLIENT_MANAGER',
            'CONSULTANT',
            // 'LIA',
            // 'SUB_CONSULTANT',
          ],
        },
        // Conditional team items
        // ...(teamItems?.length > 0 ? [{
        //   subheader: 'My Teams',
        //   items: teamItems
        // }] : []),
        // {
        //   title: 'Teams',
        //   icon: ICONS.teams,
        //   path: PATH_DASHBOARD.team.root,
        //   children: [
        ...teamObjects?.map((team) => ({
          title: team.title,
          path: team.path,
          icon: ICONS.teams,
        })),
        //   ],
        // }
        // ...teamObjects.map((team) => ({
        //   title: team.title,
        //   path: team.path,
        //   icon: ICONS.teams,
        // })),
      ],
    },
    {
      subheader: 'Portal',
      fixed: true,
      items: [
        {
          title: "What's New",
          path: PATH_DASHBOARD.whatsNew,
          icon: ICONS.dev,
        },
      ],
    },
    // {
    //   items: [
    //   ],
    // },
  ];
};

export default useDynamicNavConfig;

// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
// utils
import { bgBlur } from '../../utils/cssStyles';

// ----------------------------------------------------------------------

export default function StyledChart() {
  const theme = useTheme();

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '.apexcharts-canvas': {
          // Tooltip
          '.apexcharts-xaxistooltip': {
            ...bgBlur({ color: theme.palette.background.default }),
            border: 0,
            color: theme.palette.text.primary,
            boxShadow: theme.customShadows.dropdown,
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            '&:before': { borderBottomColor: 'transparent' },
            '&:after': {
              borderBottomColor: alpha(theme.palette.background.default, 0.8),
            },
          },
          '.apexcharts-tooltip.apexcharts-theme-light': {
            ...bgBlur({ color: theme.palette.background.default }),
            border: 0,
            boxShadow: theme.customShadows.dropdown,
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            '.apexcharts-tooltip-title': {
              border: 0,
              textAlign: 'center',
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: alpha(theme.palette.grey[500], 0.16),
              color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary'],
            },
          },

          // Legend
          '.apexcharts-legend': {
            padding: 0,
            top: '-27px!important',
            left: 'unset!important',
            right: '0px',
            height: '44px',
            maxWidth: '600px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
            columnGap: '10px',
            justifyItems: 'stretch',
            justifyContent: 'start',
          },
          '.apexcharts-legend-series': {
            display: 'flex !important',
            alignItems: 'center',
            textWrap: 'nowrap',
          },
          '.apexcharts-legend-marker': {
            marginRight: 8,
            minWidth: '12px',
          },
          '.apexcharts-legend-text': {
            lineHeight: '18px',
            textTransform: 'capitalize',
          },
          '.apexcharts-menu-icon': {
            top: '-90px',
          },
        },
        // '.rootlytics-main-chart::before': {
        // },
        '.rootlytics-chart': {
          pb: 1,
          '.apexcharts-canvas': {
            '::before': {
              content: "' '",
              display: 'block',
              position: 'absolute',
              width: '100%',
              borderRadius: `${theme.shape.borderRadius}px`,
              background: `linear-gradient(to bottom, ${alpha(theme.palette.primary.dark, 0.15)} 0%, ${alpha(
                theme.palette.info.main,
                0.15
              )} 100%)`,
              background: `linear-gradient(173deg, ${alpha(theme.palette.primary.main, 0.18)} 0%, ${alpha(
                // theme.palette.secondary.main,
                theme.palette.info.main,
                0.1
              )} 100%)`,
              boxShadow: theme.customShadows.z12,
            },
          },
        },
        '.rootlytics-chart--no-toolbar': {
          '.apexcharts-canvas': {
            '::before': {
              height: '353px',
              top: '0px',
            },
          },
        },
        '.rootlytics-main-chart': {
          '.apexcharts-canvas': {
            '::before': {
              height: '353px',
              top: '44px',
            },
          },
        },
        '.rootlytics-main-chart.rootlytics-chart--no-toolbar': {
          '.apexcharts-legend': {
            opacity: 0,
            '.apexcharts-legend-series': {
              display: 'none!important',
            },
          },
          // '.apexcharts-canvas': {
          //   top: '44px',
          // },
        },
        '.rootlytics-chart-label': {
          ...theme.typography.tableHead,
          color: theme.palette.text.secondary,
        },

        '.rootlytics-main-chart--series-4 .apexcharts-legend': {
          maxWidth: '320px',
        },
      }}
    />
  );

  return inputGlobalStyles;
}

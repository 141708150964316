import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const PeriodPickerMenu = ({ anchorEl, open, onClose, items, selectedItem, onItemClick, sx }) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    PaperProps={{
      style: {
        width: 'fit-content',
      },
    }}
    sx={sx}
  >
    {items.map((item) => (
      <MenuItem
        key={item.value}
        selected={item.value === selectedItem}
        onClick={() => onItemClick(item.value)}
        sx={{ ...sx }}
      >
        {item.name}
      </MenuItem>
    ))}
  </Menu>
);

PeriodPickerMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onItemClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

PeriodPickerMenu.defaultProps = {
  anchorEl: null,
  sx: {},
};

export default PeriodPickerMenu;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Box, Button, Card, Collapse, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useLocation, useNavigate } from 'react-router';
import { fMonth } from 'src/utils/formatTime';
import { getInitialPeriodState } from 'src/utils/periodUtils';
import useResponsive from 'src/hooks/useResponsive';
import { months } from 'src/assets/data';
import {
  setPeriod,
  setPeriodYear,
  setPeriodMonth,
  setPeriodType,
  setDisplayClientsMonthFinished,
} from 'src/redux/slices/period';
import {
  IconCalendarBlankLight,
  IconCaretLeftLight,
  IconCaretRightLight,
  IconCheckLight,
  IconInfoLight,
  IconWarningLight,
} from 'src/components/icon-components-ph';
import CustomDivider from 'src/components/divider/CustomDivider';
import SpinnerLoader from 'src/components/loaders/SpinnerLoader';
import TooltipIconPH from 'src/components/tooltip-icon/TooltipIconPH';
import { IconReset } from 'src/components/icon-components';
import PeriodPickerForm from './PeriodPickerForm';
import PeriodPickerButton from './PeriodPickerButton';
import PeriodPickerMenu from './PeriodPickerMenu';
import { TOP_OFFSET, ICON_SIZE, buttonStyle, caretButtonStyle, resetButtonStyle } from './PeriodPickerStyles';

export function PeriodPicker({ isRootlytics, disablePeriodTypeChange }) {
  const [anchorElYear, setAnchorElYear] = useState(null);
  const [anchorElMonth, setAnchorElMonth] = useState(null);
  const [PeriodPickerFormAnchorEl, setPeriodPickerFormAnchorEl] = useState(null);

  const { periodYear, periodMonth, periodMessage, periodType, displayClientsMonthFinished } = useSelector(
    (state) => state.period
  );
  const { clientsMonthFinished, isLoadingClientsMonthFinished } = useSelector((state) => state.client);

  const { isLoading: isLoadingInvoices } = useSelector((state) => state.invoice);
  const { isLoading: isLoadingClients } = useSelector((state) => state.client);
  const { isLoading: isLoadingPlanned } = useSelector((state) => state.timeLog);
  const { isLoading: isLoadingConsultant } = useSelector((state) => state.consultant);
  const { isLoading: isLoadingReports } = useSelector((state) => state.reports);

  const [isLoading, setIsLoading] = useState(
    isLoadingInvoices || isLoadingClients || isLoadingPlanned || isLoadingConsultant || isLoadingReports
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const location = useLocation();
  const cardRef = useRef(null);
  const isHandheldScreen = useResponsive('down', 'md');

  const fullYear = periodType === 'year';
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const isCurrentDate = periodYear == currentYear && (fullYear || periodMonth == currentMonth);
  const years = Array.from({ length: 4 }, (_, i) => currentYear - i + 1);
  const bgColor = theme.palette.secondary.main;
  const bgHoverColor = theme.palette.secondary.dark;
  const lineColor = theme.palette.primary.main;
  const lineHoverColor = theme.palette.primary.main;
  const notThisMonthMessage = fullYear
    ? 'The selected year is not the current year'
    : 'The selected period is not the current period';

  // EFFECTS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  // Update loading state based on various loading flags
  useEffect(() => {
    setIsLoading(isLoadingInvoices || isLoadingClients || isLoadingPlanned || isLoadingConsultant || isLoadingReports);
  }, [isLoadingInvoices, isLoadingClients, isLoadingPlanned, isLoadingConsultant, isLoadingReports]);

  // Initialize period state on component mount or route change
  useEffect(() => {
    const initialState = getInitialPeriodState();
    dispatch(setPeriodYear(initialState.periodYear));
    dispatch(setPeriodMonth(initialState.periodMonth));
    dispatch(setPeriodType('month'));
  }, [dispatch, location.pathname]);

  // Update URL and localStorage when period changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const yearParam = isRootlytics ? 'rootlytics_year' : 'year';
    const yearStorageItemName = isRootlytics ? 'rootlyticsYear' : 'periodYear';

    queryParams.set(yearParam, periodYear);
    localStorage.setItem(yearStorageItemName, periodYear);

    if (!fullYear) {
      queryParams.set('month', periodMonth);
      localStorage.setItem('periodMonth', periodMonth);
    } else {
      queryParams.delete('month');
    }

    if (isRootlytics) {
      queryParams.delete('year');
    } else {
      queryParams.delete('rootlytics_year');
    }

    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  }, [periodMonth, periodYear, isRootlytics, fullYear, navigate, location.pathname]);

  // HANDLERS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const handlePreviousPeriod = () => {
    let newYear = periodYear;
    let newMonth = parseInt(periodMonth, 10) - (fullYear ? 0 : 1);

    if (fullYear) {
      newYear -= 1;
    } else {
      if (newMonth < 1) {
        newYear -= 1;
        newMonth = 12;
      }
      dispatch(setPeriodMonth(newMonth));
    }

    const newStartDate = `${newYear}-${newMonth.toString().padStart(2, '0')}-01`;

    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(newYear));
  };

  const handleNextPeriod = () => {
    let newYear = periodYear;
    let newMonth = parseInt(periodMonth, 10) + (fullYear ? 0 : 1);

    if (fullYear) {
      newYear += 1;
    } else {
      if (newMonth > 12) {
        newYear += 1;
        newMonth = 1;
      }
      dispatch(setPeriodMonth(newMonth));
    }

    dispatch(setPeriodYear(newYear));
    dispatch(setPeriod(newYear + '-' + newMonth.toString().padStart(2, '0') + '-01'));
  };

  const handleYearChange = (year) => {
    dispatch(setPeriod(year + '-' + periodMonth.toString().padStart(2, '0') + '-01'));
    dispatch(setPeriodYear(year));
    setAnchorElYear(null);
  };

  const handleMonthChange = (month) => {
    dispatch(setPeriod(periodYear + '-' + month.toString().padStart(2, '0') + '-01'));
    dispatch(setPeriodMonth(month));
    setAnchorElMonth(null);
  };

  const handleCloseMonth = () => {
    setAnchorElMonth(null);
  };

  const handleCloseYear = () => {
    setAnchorElYear(null);
  };

  const handlePeriodPickerFormClick = () => {
    setPeriodPickerFormAnchorEl(cardRef.current);
  };

  const handlePeriodPickerFormClose = () => {
    setPeriodPickerFormAnchorEl(null);
  };

  const handleResetPeriod = () => {
    const newStartDate = `${currentYear}-${currentMonth}-01`;
    // const newEndDate = `${currentYear}-12-01`;

    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(currentYear));
    dispatch(setPeriodMonth(currentMonth));
  };

  return (
    <Stack direction="row" alignItems="center">
      <Card ref={cardRef} sx={{ background: bgColor, p: 0 }}>
        <Stack direction="row" alignItems="center" spacing={0}>
          <Button onClick={handlePreviousPeriod} sx={{ ...caretButtonStyle(theme, lineColor, bgHoverColor) }}>
            <IconCaretLeftLight size={ICON_SIZE} />
          </Button>

          <Stack direction="column" justifyContent="center" spacing={0} sx={{ position: 'relative' }}>
            <Typography variant="labelXS" color="textLight.secondary" sx={{ position: 'absolute', top: '8px' }}>
              {fullYear ? 'Year' : 'Period'}
            </Typography>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0}>
              <Stack direction="row" alignItems="center" style={{ width: '48px' }}>
                <PeriodPickerButton
                  onClick={(e) => setAnchorElYear(e.currentTarget)}
                  disabled={isLoading}
                  sx={buttonStyle(theme, lineHoverColor)}
                >
                  {periodYear}
                </PeriodPickerButton>

                {!fullYear && (
                  <Typography variant="buttonXXL" color="textLight.primary" sx={{ pt: TOP_OFFSET }}>
                    ,
                  </Typography>
                )}
              </Stack>

              {!fullYear && (
                <Stack sx={{ width: '38.5px' }}>
                  <PeriodPickerButton
                    onClick={(e) => setAnchorElMonth(e.currentTarget)}
                    disabled={isLoading}
                    sx={{ ...buttonStyle(theme, lineHoverColor), justifyContent: 'flex-end' }}
                  >
                    {fMonth(new Date(periodYear, periodMonth - 1), 'MMM')}
                  </PeriodPickerButton>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Button onClick={handleNextPeriod} sx={{ ...caretButtonStyle(theme, lineColor, bgHoverColor) }}>
            <IconCaretRightLight size={ICON_SIZE} />
          </Button>

          <Collapse
            in={!isCurrentDate}
            timeout="auto"
            unmountOnExit
            sx={{
              ml: '-8px',
              '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center' },
            }}
          >
            <Tooltip title="Reset period to current month" arrow>
              <Button
                disabled={isLoading}
                onClick={handleResetPeriod}
                sx={{ ...caretButtonStyle(theme, lineColor, bgHoverColor), ...resetButtonStyle(theme) }}
              >
                <IconReset size={'18px'} />
              </Button>
            </Tooltip>
          </Collapse>

          <CustomDivider orientation="vertical" flexItem />

          {!fullYear && (
            <Button
              aria-controls="period-form-menu"
              aria-haspopup="true"
              onClick={handlePeriodPickerFormClick}
              disabled={isLoading}
              sx={{ ...caretButtonStyle(theme, lineColor, bgHoverColor), px: isLoading ? '3px!important' : '5px' }}
            >
              {isLoading ? <SpinnerLoader color="primary.main" /> : <IconCalendarBlankLight size="21px" />}
            </Button>
          )}

          {!fullYear && (
            <PeriodPickerForm
              anchorEl={PeriodPickerFormAnchorEl}
              open={Boolean(PeriodPickerFormAnchorEl)}
              onClose={handlePeriodPickerFormClose}
              years={years}
              theme={theme}
              fullYear={fullYear}
            />
          )}
        </Stack>

        <PeriodPickerMenu
          anchorEl={anchorElYear}
          open={Boolean(anchorElYear)}
          onClose={handleCloseYear}
          items={years.map((year) => ({ value: year, name: year }))}
          selectedItem={periodYear}
          onItemClick={handleYearChange}
          sx={{ ...theme.typography.body2 }}
        />

        <PeriodPickerMenu
          anchorEl={anchorElMonth}
          open={Boolean(anchorElMonth)}
          onClose={handleCloseMonth}
          items={months}
          selectedItem={periodMonth}
          onItemClick={handleMonthChange}
          sx={{ '& .MuiList-root': { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } }}
        />
      </Card>

      <Collapse
        in={periodMessage}
        timeout="auto"
        unmountOnExit
        sx={{
          ml: 1,
          '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
          color: theme.palette.text.secondary,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            p: 0.25,
            borderRadius: '50%',
            background: theme.palette.info.main,
          }}
        >
          <TooltipIconPH title={periodMessage} icon="IconInfoLight" color="primary.main" />
        </Box>
      </Collapse>

      {displayClientsMonthFinished && (
        <Collapse
          in={!isLoadingClientsMonthFinished}
          timeout="auto"
          unmountOnExit
          orientation="horizontal"
          sx={{
            ml: 1,
            '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
          }}
        >
          <Stack
            direction="row"
            gap={1}
            sx={{
              p: 1,
              background: clientsMonthFinished ? theme.palette.success.lighter : theme.palette.warning.lighter,
              color: clientsMonthFinished ? theme.palette.success.darker : theme.palette.warning.darker,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            {clientsMonthFinished ? <IconCheckLight size={'19px'} /> : <IconWarningLight size={'19px'} />}
            <Typography variant="meta" noWrap>
              {clientsMonthFinished ? `Clients finished for period` : 'Clients not finished for period'}
            </Typography>
          </Stack>
        </Collapse>
      )}

      <Collapse
        in={!isCurrentDate && !isLoading}
        timeout="auto"
        unmountOnExit
        orientation="horizontal"
        sx={{
          ml: 1,
          '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
          color: theme.palette.text.secondary,
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{
            p: 1,
            background: theme.palette.info.lighter,
            color: theme.palette.info.darker,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          {!isHandheldScreen ? (
            <>
              <IconInfoLight size={'19px'} />{' '}
              <Typography variant="meta" noWrap>
                {notThisMonthMessage}
              </Typography>
            </>
          ) : (
            <Tooltip title={notThisMonthMessage}>
              <IconInfoLight size={'19px'} />
            </Tooltip>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}

PeriodPicker.propTypes = {
  isRootlytics: PropTypes.bool,
  disablePeriodTypeChange: PropTypes.bool,
};

PeriodPicker.defaultProps = {
  isRootlytics: false,
  disablePeriodTypeChange: false,
};

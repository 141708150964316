// ToggleButton.js
import { alpha } from '@mui/material/styles';

// Define the color set
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

export default function ToggleButton(theme) {
  const rootStyle = (ownerState) => {
    // Check for 'standard' and default color props
    const isStandard = ownerState.standard || ownerState.color === 'standard';

    // Default styles for standard buttons
    const standardStyles = isStandard
      ? {
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.12),
            color: theme.palette.text.primary,
          },
          '&.Mui-selected': {
            borderColor: 'inherit',
            backgroundColor: alpha(theme.palette.info.main, 0.16),
            color: theme.palette.text.primary,
          },
          '&:hover:not(.Mui-selected)': {
            backgroundColor: alpha(theme.palette.info.main, 0.08),
            color: theme.palette.text.primary,
          },
          '&.Mui-selected:hover': {
            borderColor: 'inherit',
            backgroundColor: alpha(theme.palette.info.main, 0.2),
            color: theme.palette.text.primary,
          },
          ...theme.typography.label,
          flexGrow: 1,
          color: theme.palette.text.secondary,
          margin: '0 8px 0 0',
          borderRadius: theme.shape.borderRadius,
        }
      : {};

    // Styles based on the COLORS array
    const colorStyles = COLORS.reduce(
      (acc, color) => ({
        ...acc,
        ...(ownerState.color === color && {
          '&:hover': {
            borderColor: alpha(theme.palette[color].main, 0.48),
            backgroundColor: alpha(theme.palette[color].light, ['primary', 'warning'].includes(color) ? 0.7 : 0.48),
            color: theme.palette[color].darker,
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            borderColor: theme.palette[color].main,
            backgroundColor: alpha(theme.palette[color].light, ['primary', 'warning'].includes(color) ? 0.7 : 0.48),
            color: theme.palette[color].darker,
          },
          backgroundColor: alpha(theme.palette[color].light, ['primary', 'warning'].includes(color) ? 0.25 : 0.15),
          color: alpha(theme.palette[color].darker, 0.45),
        }),
      }),
      {}
    );

    // Default style applied to all toggle buttons
    const defaultStyle = {
      ...theme.typography.label,
      flexGrow: 1,
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[500],
      borderRadius: theme.shape.borderRadius,
    };

    // Disabled state styles
    const disabledState = {
      '&.Mui-disabled': {
        '&.Mui-selected': {
          color: theme.palette.action.disabled,
          backgroundColor: theme.palette.action.selected,
          borderColor: theme.palette.action.disabledBackground,
        },
      },
    };

    return { ...defaultStyle, ...standardStyles, ...colorStyles, ...disabledState };
  };

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }) => rootStyle(ownerState),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const isStandard = ownerState.standard;
          return {
            borderRadius: isStandard ? 0 : theme.shape.borderRadius,
            backgroundColor: 'initial',
            border: `solid 0.5px ${theme.palette.grey[500]}`,
            ...(isStandard && {
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
            }),
            justifyContent: 'space-between',
            display: 'flex',
          };
        },
        grouped: ({ ownerState }) => {
          const isStandard = ownerState.standard;
          return {
            margin: isStandard ? '15.5px 4px 4px 0' : '4px',
            borderColor: 'transparent !important',
            borderRadius: `${theme.shape.borderRadius}px`,
            height: isStandard ? '26px' : 'auto',
            '&:last-child': {
              marginRight: isStandard && 0,
            },
          };
        },
      },
    },
  };
}

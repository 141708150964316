/**
 * @file src/components/PeriodPickerForm.js
 *
 * @description
 * The `PeriodPickerForm` component provides a dropdown form for selecting and setting
 * a specific period (year and month). It allows users to update the period and
 * triggers actions to update the application state accordingly.
 */

// IMPORTS
// Core
import React, { useEffect, useState } from 'react';
// MUI
import { Button, Menu, MenuItem, FormControl, Box, Stack, Typography } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Redux Actions
import { setPeriod, setPeriodYear, setPeriodMonth } from 'src/redux/slices/period';
// Data
import { months } from 'src/assets/data';

// ⭐️ MAIN COMPONENT ⭐️ ********************************************************
/**
 * @component PeriodPickerForm
 *
 * @param {Object} props - The component props.
 * @param {HTMLElement} props.anchorEl - The element that anchors the dropdown menu.
 * @param {boolean} props.open - Indicates whether the menu is open.
 * @param {Function} props.onClose - Function to close the dropdown menu.
 * @param {Array<number>} props.years - Array of years available for selection.
 * @param {Object} props.theme - The MUI theme object for styling.
 * @param {boolean} fullYear - If true, the form only allows selecting a year.
 *
 * @example
 * <PeriodPickerForm anchorEl={anchorEl} open={open} onClose={handleClose} years={years} theme={theme} fullYear={true} />
 */
function PeriodPickerForm({ anchorEl, open, onClose, years, theme, fullYear }) {
  // CONTEXTS
  const dispatch = useDispatch();
  const { periodYear, periodMonth } = useSelector((state) => state.period);

  // STATES
  const [selectedYear, setSelectedYear] = useState(periodYear);
  const [selectedMonth, setSelectedMonth] = useState(periodMonth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // EFFECTS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  // Sync state with current period values
  useEffect(() => {
    setSelectedYear(periodYear);
    setSelectedMonth(periodMonth);
  }, [periodYear, periodMonth]);

  // Update button disabled state based on the selected year and month
  useEffect(() => {
    setIsButtonDisabled(!(selectedYear !== periodYear || selectedMonth !== periodMonth));
  }, [selectedYear, selectedMonth, periodYear, periodMonth]);

  // HANDLERS  ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const handleSubmit = (event) => {
    event.preventDefault();

    const newStartMonth = fullYear ? '01' : selectedMonth;
    const newStartDate = selectedYear + '-' + newStartMonth + '-01';

    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(selectedYear));
    dispatch(setPeriodMonth(selectedMonth));

    dispatch(setPeriod(newStartDate));
    dispatch(setPeriodYear(selectedYear));

    onClose();
  };

  // DERIVED CONSTANTS ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const borderColor = theme.palette.mode === 'light' ? theme.palette.divider : theme.palette.dividerLight;

  // DERIVED STYLES  ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  const inputStyle = {
    width: 'auto',
  };

  const inputListStyle = {
    border: `.5px solid ${borderColor}`,
    borderRadius: theme.shape.borderRadius,
  };

  const menuItemStyle = {
    px: 1,
    py: 0.5,
    border: `.5px solid ${theme.palette.dividerLight}`,
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
    ...theme.typography.body2,
  };

  const labelStyle = {
    mb: 1,
  };

  // 🚀 MAIN RENDER 🚀 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiList-root': {
          p: 0,
        },
      }}
      PaperProps={{
        style: {
          width: 'fit-content',
          maxWidth: '100%',
          maxHeight: 'auto',
        },
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1}>
          {/* Year Selection */}
          <FormControl fullWidth margin="none" sx={{ ...inputStyle }}>
            <Typography variant="labelSM" sx={{ ...labelStyle }}>
              Year
            </Typography>

            <Box sx={{ ...inputListStyle }}>
              {years.map((year) => (
                <MenuItem
                  key={year}
                  selected={year == selectedYear}
                  onClick={() => setSelectedYear(year)}
                  sx={{
                    ...menuItemStyle,
                    color: year == periodYear ? theme.palette.warning.darker : theme.palette.text.secondary,
                    background: year == periodYear ? theme.palette.primary.lighter : 'transparent',
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </Box>
          </FormControl>

          {/* Month Selection */}
          {!fullYear && (
            <FormControl fullWidth margin="none" sx={{ ...inputStyle }}>
              <Typography variant="labelSM" sx={{ ...labelStyle }}>
                Month
              </Typography>

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', ...inputListStyle }}>
                {months.map((month) => (
                  <MenuItem
                    key={month.value}
                    selected={month.value == selectedMonth}
                    onClick={() => setSelectedMonth(month.value)}
                    sx={{
                      ...menuItemStyle,
                      color: month.value == periodMonth ? theme.palette.warning.darker : theme.palette.text.secondary,
                      background: month.value == periodMonth ? theme.palette.primary.lighter : 'transparent',
                    }}
                  >
                    {month.name}
                  </MenuItem>
                ))}
              </Box>
            </FormControl>
          )}
        </Stack>

        {/* Submit Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 0 }}>
          <Button variant="contained" size="small" color="primary" onClick={handleSubmit} disabled={isButtonDisabled}>
            Set new Period
          </Button>
        </Box>
      </Stack>
    </Menu>
  );
}

// EXPORTS ---------------------------------------------------------------------
export default PeriodPickerForm;

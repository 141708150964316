import numeral from 'numeral';

// ----------------------------------------------------------------------

// Swedish formatting
numeral.register('locale', 'sv', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'md',
    trillion: 'bn',
  },
  ordinal: () => '.',
  currency: {
    symbol: 'kr',
  },
});

numeral.locale('sv');

export function fNumber(number) {
  return numeral(Number(number)).format('0,0.[000000000]');
}

export function fCurrency(number, showDecimals = true) {
  number = Number(number);
  // Check if the number is an integer
  let formattedNumber;
  if (Number.isInteger(number)) {
    formattedNumber = numeral(number).format('0,0');
  } else {
    // If not, format it with up to two decimals, or not if showDecimals is false
    formattedNumber = numeral(number).format(showDecimals ? '0,0.00' : '0,0');
  }

  // Append ' kr' to the formatted number
  return formattedNumber + ' kr';
}

export function fCurrencyRounded(number) {
  // Round the number
  const roundedNumber = Math.round(number);
  // Format the rounded number
  return numeral(roundedNumber).format('0,0') + ' kr';
}

export function fRate(number) {
  // Round the number
  const roundedNumber = Math.round(number);
  // Check if the original number and rounded number are the same
  const isInteger = number === roundedNumber;
  // Check if the original number had ".00" as the decimal part
  const hasZeroDecimal = Number.isInteger(number) || number % 1 === 0;
  // Format the rounded number and prepend "~" if it's not an integer and doesn't have ".00" as the decimal part
  return (
    (isInteger || hasZeroDecimal ? numeral(roundedNumber).format('0,0') : `~${numeral(roundedNumber).format('0,0')}`) +
    ' kr/h'
  );
}

export function fPercent(number, precision = 2) {
  const format = numeral(Number(number) || 0).format(`0,0.${['0'.repeat(precision)]}`);

  return `${format} %`;
}

export function fPercentWithTilde(number, precision = 2) {
  const format = numeral(Number(number) || 0).format(`0,0.${['0'.repeat(precision)]}`);
  const hasDecimal = number % 1 !== 0; // Check if there is a non-zero decimal part

  return hasDecimal ? `~${format} %` : `${format} %`;
}

// New percent format function with "+" prefix for positive values
export function fPercentWithSign(number, precision = 1) {
  const format = numeral(Number(number) || 0).format(`0,0.[${'0'.repeat(precision)}]`);
  const sign = number > 0 ? '+' : '';

  return `${sign}${format} %`;
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fHours(number, showDecimals = true) {
  // Round to the nearest 0.5
  const roundedNumber = Math.round(number * 2) / 2;

  // Format the rounded number with or without decimals
  const format = roundedNumber ? numeral(roundedNumber).format(showDecimals ? '0,0.[0]' : '0,0') : '';

  // Append "h" to the formatted number
  return format ? `${format} h` : '0 h';
}

export function fShortenNumberOneOptionalDecimal(number) {
  const format = number ? numeral(number).format('0.0a') : '';

  return format.replace(/,0(?=[kmbt])/gi, '');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

import { alpha } from '@mui/system';

const CONTENT_HEIGHT = 48;
export const TOP_OFFSET = '6px';
export const ICON_SIZE = '20px';

export const buttonStyle = (theme, lineHoverColor) => ({
  height: CONTENT_HEIGHT,
  p: 0,
  pt: TOP_OFFSET,
  transition: 'background-color 0.2s ease-in-out',
  '.MuiTypography-root': {
    borderBottom: '1.5px solid transparent',
    transition: 'border-color 0.2s ease-in-out',
  },
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
    '.MuiTypography-root': {
      borderBottomColor: lineHoverColor,
    },
  },
});

export const caretButtonStyle = (theme, lineColor, bgHoverColor, lineHoverColor) => ({
  color: lineColor,
  '&&': {
    px: '5px',
    height: CONTENT_HEIGHT,
    transition: 'background-color 0.2s ease-in-out',
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
      color: lineHoverColor,
      '& .MuiBox-root': { background: bgHoverColor },
    },
    '& .MuiBox-root': { transition: 'background-color 0.2s ease-in-out', borderRadius: '50%', padding: '3px' },
  },
});

export const resetButtonStyle = (theme) => ({
  color: alpha(theme.palette.common.white, 0.74),
  '&:hover': {
    color: theme.palette.primary.main,
  },
});
